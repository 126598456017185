// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form[data-v-722a846b] {
  text-align: start;
  font-size: 1rem;
  margin: 0;
}
.login-form__fieldset[data-v-722a846b] {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.login-form__headline[data-v-722a846b] {
  text-align: center;
  overflow-wrap: anywhere;
}
.login-form[data-v-722a846b] input:invalid:not(:user-invalid) {
  border-color: var(--color-border-maxcontrast) !important;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
