import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import { defineComponent } from 'vue';
import { NoValidCredentials, startAuthentication, finishAuthentication } from "../../services/WebAuthnAuthenticationService.ts";
import NcEmptyContent from '@nextcloud/vue/components/NcEmptyContent';
import NcTextField from '@nextcloud/vue/components/NcTextField';
import InformationIcon from 'vue-material-design-icons/Information.vue';
import LoginButton from "./LoginButton.vue";
import LockOpenIcon from 'vue-material-design-icons/LockOpen.vue';
import logger from "../../logger";
export default defineComponent({
  name: 'PasswordLessLoginForm',
  components: {
    LoginButton,
    InformationIcon,
    LockOpenIcon,
    NcEmptyContent,
    NcTextField
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    redirectUrl: {
      type: [String, Boolean],
      default: false
    },
    autoCompleteAllowed: {
      type: Boolean,
      default: true
    },
    isHttps: {
      type: Boolean,
      default: false
    },
    isLocalhost: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      supportsWebauthn: browserSupportsWebAuthn()
    };
  },
  data() {
    return {
      user: this.username,
      loading: false,
      validCredentials: true
    };
  },
  methods: {
    async authenticate() {
      // check required fields
      if (!this.$refs.loginForm.checkValidity()) {
        return;
      }
      console.debug('passwordless login initiated');
      try {
        const params = await startAuthentication(this.user);
        await this.completeAuthentication(params);
      } catch (error) {
        if (error instanceof NoValidCredentials) {
          this.validCredentials = false;
          return;
        }
        logger.debug(error);
      }
    },
    changeUsername(username) {
      this.user = username;
      this.$emit('update:username', this.user);
    },
    completeAuthentication(challenge) {
      const redirectUrl = this.redirectUrl;
      return finishAuthentication(challenge).then(_ref => {
        let {
          defaultRedirectUrl
        } = _ref;
        console.debug('Logged in redirecting');
        // Redirect url might be false so || should be used instead of ??.
        window.location.href = redirectUrl || defaultRedirectUrl;
      }).catch(error => {
        console.debug('GOT AN ERROR WHILE SUBMITTING CHALLENGE!');
        console.debug(error); // Example: timeout, interaction refused...
      });
    },
    submit() {
      // noop
    }
  }
});