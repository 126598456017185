var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("form", {
    staticClass: "reset-password-form",
    on: {
      submit: function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("h2", [_vm._v(_vm._s(_vm.t("core", "Reset password")))]), _vm._v(" "), _c("NcTextField", {
    attrs: {
      id: "user",
      value: _vm.user,
      name: "user",
      maxlength: 255,
      autocapitalize: "off",
      label: _vm.t("core", "Login or email"),
      error: _vm.userNameInputLengthIs255,
      "helper-text": _vm.userInputHelperText,
      required: ""
    },
    on: {
      "update:value": function ($event) {
        _vm.user = $event;
      },
      change: _vm.updateUsername
    }
  }), _vm._v(" "), _c("LoginButton", {
    attrs: {
      loading: _vm.loading,
      value: _vm.t("core", "Reset password")
    }
  }), _vm._v(" "), _c("NcButton", {
    attrs: {
      type: "tertiary",
      wide: ""
    },
    on: {
      click: function ($event) {
        return _vm.$emit("abort");
      }
    }
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Back to login")) + "\n\t")]), _vm._v(" "), _vm.message === "send-success" ? _c("NcNoteCard", {
    attrs: {
      type: "success"
    }
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "If this account exists, a password reset message has been sent to its email address. If you do not receive it, verify your email address and/or Login, check your spam/junk folders or ask your local administration for help.")) + "\n\t")]) : _vm.message === "send-error" ? _c("NcNoteCard", {
    attrs: {
      type: "error"
    }
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Couldn't send reset email. Please contact your administrator.")) + "\n\t")]) : _vm.message === "reset-error" ? _c("NcNoteCard", {
    attrs: {
      type: "error"
    }
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Password cannot be changed. Please contact your administrator.")) + "\n\t")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };