var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "guest-box login-box"
  }, [!_vm.hideLoginForm || _vm.directLogin ? [_c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [!_vm.passwordlessLogin && !_vm.resetPassword && _vm.resetPasswordTarget === "" ? _c("div", {
    staticClass: "login-box__wrapper"
  }, [_c("LoginForm", {
    attrs: {
      username: _vm.user,
      "redirect-url": _vm.redirectUrl,
      "direct-login": _vm.directLogin,
      messages: _vm.messages,
      errors: _vm.errors,
      "throttle-delay": _vm.throttleDelay,
      "auto-complete-allowed": _vm.autoCompleteAllowed,
      "email-states": _vm.emailStates
    },
    on: {
      "update:username": function ($event) {
        _vm.user = $event;
      },
      submit: function ($event) {
        _vm.loading = true;
      }
    }
  }), _vm._v(" "), _vm.hasPasswordless ? _c("NcButton", {
    attrs: {
      type: "tertiary",
      wide: ""
    },
    on: {
      click: function ($event) {
        $event.preventDefault();
        _vm.passwordlessLogin = true;
      }
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("core", "Log in with a device")) + "\n\t\t\t\t")]) : _vm._e(), _vm._v(" "), _vm.canResetPassword && _vm.resetPasswordLink !== "" ? _c("NcButton", {
    attrs: {
      id: "lost-password",
      href: _vm.resetPasswordLink,
      type: "tertiary-no-background",
      wide: ""
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("core", "Forgot password?")) + "\n\t\t\t\t")]) : _vm.canResetPassword && !_vm.resetPassword ? _c("NcButton", {
    attrs: {
      id: "lost-password",
      type: "tertiary",
      wide: ""
    },
    on: {
      click: function ($event) {
        $event.preventDefault();
        _vm.resetPassword = true;
      }
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("core", "Forgot password?")) + "\n\t\t\t\t")]) : _vm._e()], 1) : !_vm.loading && _vm.passwordlessLogin ? _c("div", {
    key: "reset-pw-less",
    staticClass: "login-additional login-box__wrapper"
  }, [_c("PasswordLessLoginForm", {
    attrs: {
      username: _vm.user,
      "redirect-url": _vm.redirectUrl,
      "auto-complete-allowed": _vm.autoCompleteAllowed,
      "is-https": _vm.isHttps,
      "is-localhost": _vm.isLocalhost
    },
    on: {
      "update:username": function ($event) {
        _vm.user = $event;
      },
      submit: function ($event) {
        _vm.loading = true;
      }
    }
  }), _vm._v(" "), _c("NcButton", {
    attrs: {
      type: "tertiary",
      "aria-label": _vm.t("core", "Back to login form"),
      wide: true
    },
    on: {
      click: function ($event) {
        _vm.passwordlessLogin = false;
      }
    }
  }, [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.t("core", "Back")) + "\n\t\t\t\t")])], 1) : !_vm.loading && _vm.canResetPassword ? _c("div", {
    key: "reset-can-reset",
    staticClass: "login-additional"
  }, [_c("div", {
    staticClass: "lost-password-container"
  }, [_vm.resetPassword ? _c("ResetPassword", {
    attrs: {
      username: _vm.user,
      "reset-password-link": _vm.resetPasswordLink
    },
    on: {
      "update:username": function ($event) {
        _vm.user = $event;
      },
      abort: function ($event) {
        _vm.resetPassword = false;
      }
    }
  }) : _vm._e()], 1)]) : _vm.resetPasswordTarget !== "" ? _c("div", [_c("UpdatePassword", {
    attrs: {
      username: _vm.user,
      "reset-password-target": _vm.resetPasswordTarget
    },
    on: {
      "update:username": function ($event) {
        _vm.user = $event;
      },
      done: _vm.passwordResetFinished
    }
  })], 1) : _vm._e()])] : [_c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [_c("NcNoteCard", {
    attrs: {
      type: "info",
      title: _vm.t("core", "Login form is disabled.")
    }
  }, [_vm._v("\n\t\t\t\t" + _vm._s(_vm.t("core", "The Nextcloud login form is disabled. Use another login option if available or contact your administration.")) + "\n\t\t\t")])], 1)], _vm._v(" "), _c("div", {
    staticClass: "login-box__alternative-logins",
    attrs: {
      id: "alternative-logins"
    }
  }, _vm._l(_vm.alternativeLogins, function (alternativeLogin, index) {
    return _c("NcButton", {
      key: index,
      class: [alternativeLogin.class],
      attrs: {
        type: "secondary",
        wide: true,
        role: "link",
        href: alternativeLogin.href
      }
    }, [_vm._v("\n\t\t\t" + _vm._s(alternativeLogin.name) + "\n\t\t")]);
  }), 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };