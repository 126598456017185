var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return (_vm.isHttps || _vm.isLocalhost) && _vm.supportsWebauthn ? _c("form", {
    ref: "loginForm",
    staticClass: "password-less-login-form",
    attrs: {
      "aria-labelledby": "password-less-login-form-title",
      method: "post",
      name: "login"
    },
    on: {
      submit: function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("h2", {
    attrs: {
      id: "password-less-login-form-title"
    }
  }, [_vm._v("\n\t\t" + _vm._s(_vm.t("core", "Log in with a device")) + "\n\t")]), _vm._v(" "), _c("NcTextField", {
    attrs: {
      required: "",
      value: _vm.user,
      autocomplete: _vm.autoCompleteAllowed ? "on" : "off",
      error: !_vm.validCredentials,
      label: _vm.t("core", "Login or email"),
      placeholder: _vm.t("core", "Login or email"),
      "helper-text": !_vm.validCredentials ? _vm.t("core", "Your account is not setup for passwordless login.") : ""
    },
    on: {
      "update:value": _vm.changeUsername
    }
  }), _vm._v(" "), _vm.validCredentials ? _c("LoginButton", {
    attrs: {
      loading: _vm.loading
    },
    on: {
      click: _vm.authenticate
    }
  }) : _vm._e()], 1) : !_vm.isHttps && !_vm.isLocalhost ? _c("NcEmptyContent", {
    attrs: {
      name: _vm.t("core", "Your connection is not secure"),
      description: _vm.t("core", "Passwordless authentication is only available over a secure connection.")
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c("LockOpenIcon")];
      },
      proxy: true
    }])
  }) : _c("NcEmptyContent", {
    attrs: {
      name: _vm.t("core", "Browser not supported"),
      description: _vm.t("core", "Passwordless authentication is not supported in your browser.")
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c("InformationIcon")];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };