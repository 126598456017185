// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-box[data-v-020fd45b] {
  width: 320px;
  box-sizing: border-box;
}
.login-box__wrapper[data-v-020fd45b] {
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--default-grid-baseline));
}
.login-box__alternative-logins[data-v-020fd45b] {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.fade-enter-active[data-v-020fd45b], .fade-leave-active[data-v-020fd45b] {
  transition: opacity 0.3s;
}
.fade-enter[data-v-020fd45b], .fade-leave-to[data-v-020fd45b] {
  opacity: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
