import { generateUrl } from '@nextcloud/router';
import { defineComponent } from 'vue';
import axios from '@nextcloud/axios';
import NcButton from '@nextcloud/vue/components/NcButton';
import NcTextField from '@nextcloud/vue/components/NcTextField';
import NcNoteCard from '@nextcloud/vue/components/NcNoteCard';
import AuthMixin from "../../mixins/auth.js";
import LoginButton from "./LoginButton.vue";
import logger from "../../logger.js";
export default defineComponent({
  name: 'ResetPassword',
  components: {
    LoginButton,
    NcButton,
    NcNoteCard,
    NcTextField
  },
  mixins: [AuthMixin],
  props: {
    username: {
      type: String,
      required: true
    },
    resetPasswordLink: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: false,
      loading: false,
      message: '',
      user: this.username
    };
  },
  watch: {
    username(value) {
      this.user = value;
    }
  },
  methods: {
    updateUsername() {
      this.$emit('update:username', this.user);
    },
    async submit() {
      this.loading = true;
      this.error = false;
      this.message = '';
      const url = generateUrl('/lostpassword/email');
      try {
        const {
          data
        } = await axios.post(url, {
          user: this.user
        });
        if (data.status !== 'success') {
          throw new Error(`got status ${data.status}`);
        }
        this.message = 'send-success';
      } catch (error) {
        logger.error('could not send reset email request', {
          error
        });
        this.error = true;
        this.message = 'send-error';
      } finally {
        this.loading = false;
      }
    }
  }
});